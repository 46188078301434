import { AppBar, Box, Toolbar, Typography } from '@material-ui/core';
import React from 'react';
import { Link } from './Link';

const Footer = () => {
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        flexShrink={100}
      />

      <Box clone marginTop={6}>
        <AppBar position="relative" component="footer">
          <Box clone display="flex" justifyContent="center">
            <Toolbar>
              <Typography variant="h6" component="span">
                <Link href="/feedback" color="inherit">
                  Поставити питання
                </Link>
              </Typography>
            </Toolbar>
          </Box>
        </AppBar>
      </Box>
    </>
  );
};

export default Footer;
