import { Box, Container, Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Head from 'next/head';
import React from 'react';
import AppBar from '../src/common/AppBarScroll';
import Footer from '../src/common/Footer';
import { Button } from '../src/common/Link';
import UserTypeFeaturesCard from '../src/common/UserTypeFeaturesCard';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    '@global': {
      'html, body, #__next': {
        height: '100%',
      },
      '#__next': {
        flexDirection: 'column',
        display: 'flex',
      },
    },
    img: {
      height: '100%',
      width: '50%',
      objectFit: 'cover',
      borderRadius: '50%',
    },
    hero: {
      background: 'center bottom / cover no-repeat url("/hero-bg.png")',
      color: theme.palette.primary.contrastText,
    },
    // https://material-ui.com/ru/guides/typescript/#media-queries
    buttonEqualizeWideness: {},
    '@media (min-width: 364px)': {
      buttonEqualizeWideness: {
        paddingRight: '66px',
        paddingLeft: '66px',
      },
    },
  }),
);

const Home: React.FC = () => {
  const classes = useStyles({});
  return (
    <>
      <Head>
        <title>OSA: online sales agent</title>
      </Head>

      <AppBar />

      <Box
        paddingTop={12}
        paddingBottom={32}
        marginBottom={4}
        className={classes.hero}
        component="section"
      >
        <Container>
          <Grid
            container
            alignItems="center"
            justify="space-between"
            spacing={3}
          >
            <Grid item xs={12} sm={6}>
              <Typography component="p" color="inherit">
                Наша концепція — спростити роботу дистриб'ютора і розширити його
                горизонти, при цьому всі його клієнти отримують прозорі
                пропозиції про товари і акції в додатку «OSA».
              </Typography>
            </Grid>

            <Box clone textAlign="center">
              <Grid item xs={12} sm={6}>
                <img
                  src="/logo_large.png"
                  alt="Landing logo"
                  className={classes.img}
                />
              </Grid>
            </Box>
          </Grid>
        </Container>
      </Box>

      <Container component="section">
        <Grid container spacing={4} justify="center" alignItems="stretch">
          <UserTypeFeaturesCard
            title="Дистриб'ютору"
            text="Ми робимо кожного дистриб'ютора ближче до його клієнтів,
              прайс з актуальними цінами та свіжими акційними пропозиціями
              завжди буде у ваших покупців. Прискоримо і спростимо збір
              заявок, повернень і залишків товару, а також зменшимо
              помилки в цих документах. Всі обсяги продажів і кількість
              проданого асортименту захищені шифруванням, ніхто крім Вас і
              вашого покупця не має до них доступ."
            action={
              <Button
                href="/registration/[user_type]"
                as="/registration/distributor"
                size="large"
                variant="contained"
                color="primary"
              >
                Зареєструвати дистриб'ютора
              </Button>
            }
          />

          <UserTypeFeaturesCard
            title="Магазину"
            text="Встановіть додаток «OSA» в смартфон, і ви отримаєте доступ
              до всіх дистриб'юторів, які працюють у вашому регіоні.
              Реєструйтеся та замовляйте усі товари в додатку «OSA», Ви
              отримаєте актуальні на сьогодні ціни, новинки, не пропустите
              акційні пропозиції, також отримаєте зручний доступ до
              історії замовлень і повернень."
            action={
              <Button
                href="/registration/[user_type]"
                as="/registration/rtt"
                size="large"
                variant="contained"
                color="primary"
                className={classes.buttonEqualizeWideness}
              >
                Зареєструвати магазин
              </Button>
            }
          />
        </Grid>
      </Container>

      <Footer />
    </>
  );
};

export default Home;
