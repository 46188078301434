import { Box, Grid, Paper, Typography } from '@material-ui/core';
import React from 'react';

type UserTypeFeaturesCardProps = {
  title: string;
  text: string;
  action: React.ReactNode;
};

const UserTypeFeaturesCard = ({
  title,
  text,
  action,
}: UserTypeFeaturesCardProps) => (
  <Grid item md={6}>
    <Box
      clone
      padding={4}
      paddingY={8}
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Paper variant="outlined">
        <Typography component="h2" variant="h4" paragraph align="center">
          {title}
        </Typography>

        <Box clone flexGrow={1}>
          <Typography component="p" paragraph>
            {text}
          </Typography>
        </Box>

        <Box display="flex" justifyContent="center" marginTop={6}>
          {action}
        </Box>
      </Paper>
    </Box>
  </Grid>
);

export default UserTypeFeaturesCard;
